/*
 * @Date: 2023-07-12 11:27:30
 */
import { createRouter, createWebHashHistory, RouteRecordRaw } from "vue-router";
import { getUrlParams } from "@/utils/common";
const params: AnyObject = getUrlParams(window.location.href);
const getRedirect = () => {
  if(params.countryStatus) return "/guide"
  else if (process.env.VUE_APP_START == "index") return "/marketing";
  else return "/marketing";
};
const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    redirect: getRedirect()
  },
  {
    path: "/main",
    name: "Main",
    component: () => import("@/views/New.vue")
  },
  {
    path: "/marketing",
    name: "Marketing",
    component: () => import("@/views/marketing/index.vue")
  },
  {
    path: "/privacy",
    name: "Privacy",
    component: () => import("@/views/privacy.vue")
  },
  {
    path: "/guide",
    name: "Guide",
    component: () => import("@/views/guide/index.vue")
  }
];

const router = createRouter({
  history: createWebHashHistory(),
  scrollBehavior(to, from, savedPosition) {
    // 解决路由跳转后 会滚动到底部
    // keep-alive 返回缓存页面后记录浏览位置
    if (savedPosition && to.meta.keepAlive) {
      return savedPosition;
    }
    // 异步滚动操作
    return new Promise((resolve) => {
      setTimeout(() => {
        resolve({ left: 0, top: 0 });
      }, 0);
    });
  },
  routes
});
// router.beforeEach((to, from, next) => {
//   window.document.title = 'Only 100 free places left'
//   next()
// })

export default router;
