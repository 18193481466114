/*
 * @Date: 2023-11-09 11:28:05
 */
/** 获取随机数量字符串 */
export const randomString = (len: number) => {
  len = len || 32;
  var t = "ABCDEFGHJKMNPQRSTWXYZabcdefhijkmnprstwxyz2345678",
    a = t.length,
    n = "";
  for (let i = 0; i < len; i++) {
    let index = Math.floor(Math.random() * a);
    let str = t.substr(index, 1);
    n += str;
  }
  return n;
};

/** 根据url获取参数 */
export const getParamsFromUrl = (url: string): AnyObject => {
  let paramStr = url.split("?")[1];
  if (!paramStr) return {};
  if (paramStr.indexOf("#/") > -1) paramStr = paramStr.split("#/")[0];
  let kavs = paramStr.split("&");
  let result: AnyObject = {};
  kavs.forEach((item) => {
    let kv = item.split("=");
    result[kv[0]] = kv[1];
  });
  return result;
};

export const getUrlFromParams = (params: AnyObject): string => {
  let result = "?";
  Object.keys(params).forEach((key) => {
    result += `${key}=${params[key]}&`;
    // 转义中文
    // if (key == 'app_uri') {
    //   result += `${key}=${params[key]}&`;
    // } else {
    //   result += `${key}=${encodeURIComponent(params[key])}&`;
    // }
  });
  return result.slice(0, -1);
};

export const prefixZero = (num: number, n: number) => {
  return (Array(n).join("0") + num).slice(-n);
};

export const randomNumber = (min: number, max: number) => {
  return Math.floor(Math.random() * (max - min + 1)) + min;
};


export const timeDifference  =  (time:any) =>{
  var diff = '';
  var time_diff = new Date().getTime() - time;
  // 计算相差天数  
  var days = Math.floor(time_diff / (24 * 3600 * 1000));
  if (days > 0) {
      diff += days + '天';
  }
  // 计算相差小时数  
  var leave1 = time_diff % ( 24 * 3600 * 1000); 
  var hours = Math.floor(leave1 / (3600 * 1000));
  if (hours > 0) {
      diff += hours + '小时';
  } else {
      if (diff !== '') {
          diff += hours + '小时';
      }
  }
  // 计算相差分钟数  
  var leave2 =leave1 % (3600 * 1000);
  var minutes = Math.floor(leave2 / (60 * 1000));
  if (minutes > 0) {
      diff += minutes + '分';
  } else {
      if (diff !== '') {
          diff += minutes + '分';
      }
  }
  // 计算相差秒数  
  var leave3 = leave2%(60*1000);
  var seconds = Math.round(leave3/1000);
  if (seconds > 0) {
      diff += seconds + '秒';
  } else {
      if (diff !== '') {
          diff += seconds + '秒';
      }
  }
  
  return diff;
}